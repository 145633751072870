<template>
  <div class="header"></div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.header{
  height: 50px;
  border-bottom: 1px solid #e1e1e1;
}
</style>