<template>
  <div class="menu">
    <el-menu :default-active="defaultActive" class="el-menu-vertical" router>
        <el-menu-item index="home" route="/">
          <el-icon><IconMenu /></el-icon>
          <span>首页</span>
        </el-menu-item>
        <!-- <el-menu-item index="lotteryManagement">
          <el-icon><Document /></el-icon>
          <span>抽奖管理</span>
        </el-menu-item> -->
        <el-menu-item index="activityManagement">
          <el-icon><Setting /></el-icon>
          <span>活动管理</span>
        </el-menu-item>
      </el-menu>
  </div>
</template>

<script setup>
import {  Menu as IconMenu, Setting } from '@element-plus/icons-vue';
import { ref, onMounted} from 'vue';
import { useRoute } from 'vue-router';
const route = useRoute();
const defaultActive  = ref('home');

onMounted(() => {
  init();
});

const init = () => {
  defaultActive.value = route.name;
}


</script>

<style lang="scss" scoped>
.menu {
  width: 260px;
  overflow: hidden;
  .el-menu-vertical {
    height: 100%;
  }
}
</style>